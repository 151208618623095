import React, { Component } from 'react';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p className="title">FishTudo</p>
        </header>
        <div className="content">
          <p className="subtitle">
            Lembrete: Política de privacidade
          </p>
          <p className="text">
            O Lembrete não coleta nenhum tipo de informações do usuário. Todas as informações do aplicativo ficam salvas no app.
          </p>
          <p className="itemtitle">
            1) Divulgação
          </p>
          <p className="text">
            O aplicativo Lembrete não divulga seus dados à terceiros.
          </p>
          <p className="itemtitle">
            2) Anúncios
          </p>
          <p className="text">
            Utilizamos para efeito de publicidade os anúncios do Google. Estes anúncios estão sujeitos
              às <a className="App-link" href="https://policies.google.com/privacy?hl=pt-BR">
              políticas de privacidade da Google</a> e 
              às <a className="App-link" href="https://policies.google.com/technologies/ads?hl=pt-BR">
              políticas de privacidade do Google Ads</a>.
          </p>
          <p className="itemtitle">
            3) Alterações da nossa política de privacidade
          </p>
          <p className="text">
            Reservamos o direito de modificar essa política de privacidade a qualquer momento, então por favor, revise-a com frequência. Alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação no site. Se fizermos alterações de materiais para essa política, iremos notificá-lo aqui que eles foram atualizados, para que você tenha ciência sobre quais informações coletamos, como as usamos, e sob que circunstâncias, se alguma, as usamos e/ou divulgamos.
          </p>
        </div>
        <div className="footer">
          <p className="text">
            Para baixar o app, acesse:
          </p>
          <a
            className="App-link"
            href="https://play.google.com/store/apps/details?id=fishtudo.lembrete"
            target="_blank"
            rel="noopener noreferrer"
          ><p className="text">Lembrete Grátis</p>
          </a>

          <a
            className="App-link"
            href="https://play.google.com/store/apps/details?id=fishtudo.lembrete.pro"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="text">Lembrete Pro</p>
          </a>
        </div>
      </div>
    );
  }
}

export default App;
